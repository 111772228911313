import React, { useContext } from 'react'
import loadable from '@loadable/component'
import { OptionsContext } from '/src/contexts/OptionsContext'
import { UserContext } from '/src/contexts/UserContext'
import './styles.scss'

const AppointmentPickerBlock = loadable(() =>
    import('/src/components/PageBuilder/layouts/standard/AppointmentPickerBlock')
)
const ButtonLink = loadable(() => import('/src/components/Basic/Buttons/ButtonLink'))
const CtaContainer = loadable(() => import('/src/components/Basic/CtaContainer'))
const EmailCallout = loadable(() => import('/src/components/Email/EmailCallout'))
const FooterEmail = loadable(() => import('/src/components/Email/FooterEmail'))
const LayoutInner = loadable(() => import('/src/components/Layout/LayoutInner'))
const Section = loadable(() => import('/src/components/Structure/Section/Section'))
const Seo = loadable(() => import('/src/components/Structure/Seo'))
const VideoPlayer = loadable(() => import('/src/components/Media/VideoPlayer'))

const NotQuotedEmailTemplate = ({ content }) => {
    const [options] = useContext(OptionsContext)
    const [user] = useContext(UserContext)

    const post = {
        seo: {
            title: `Discuss your quote - ${options.siteData.title}`,
            metaRobotsNoindex: 'noindex',
            metaRobotsNofollow: 'nofollow'
        }
    }

    return (
        <LayoutInner removeHeader={true} removeFooter={true}>
            <Seo post={post} />

            <main>
                <div className="row">
                    <div className="col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
                        <Section size={'sm'} className={'c-not-quoted-video-container'}>
                            <div className="row">
                                <div className="col-lg-10 offset-lg-1">
                                    <VideoPlayer
                                        data={'https://protect-line-marketing.wistia.com/medias/wxgrik2j3n'}
                                        variant={'portrait'}
                                        light={false}
                                        className="c-not-quoted-video"
                                    />
                                </div>
                            </div>
                        </Section>

                        <Section variant={'dark'} size={'sm'} className={'c-not-quoted-intro'}>
                            <div className="row">
                                <div className="col-lg-10 offset-lg-1">
                                    <CtaContainer align={'centered'} variant={'compact'}>
                                        <ButtonLink
                                            data={{
                                                url: '#form',
                                                title: 'Discuss Your Quotes'
                                            }}
                                            variant={'alt5'}
                                            size={'lg'}
                                            style={{
                                                marginBottom: '10px'
                                            }}
                                        />
                                    </CtaContainer>

                                    <p>
                                        <strong>Hi{user.name && ` ${user.name}`},</strong>
                                        <br />
                                        As we haven't spoken in a while, I wanted to send a video to check in.
                                    </p>

                                    <p>
                                        <strong>{content.figures}</strong>
                                    </p>

                                    <p>
                                        If you'd like to discuss your options further, please don't hesitate to book in
                                        a call and one of our specialists will help you find a policy that's perfect for
                                        your needs.
                                    </p>
                                </div>
                            </div>
                        </Section>

                        <AppointmentPickerBlock
                            backgroundColour={'alt'}
                            size={'sm'}
                            titleArea={{ addtitleArea: false }}
                            user={user}
                        />

                        <Section variant={'light'} size={'sm'} className={'c-not-quoted-content'}>
                            <div className="row">
                                <div className="col-lg-10 offset-lg-1">
                                    <h2>Why do I need a call?</h2>

                                    <p>
                                        Life insurance is personalised to the individual with the price affected by age,
                                        health, medical history, height, weight, occupation, and lifestyle. There are
                                        also options to consider to ensure your policy does exactly what you want it to
                                        do. Without further information about you, we cannot provide you with an
                                        accurate quote.
                                    </p>

                                    <p>
                                        <strong>
                                            Phone calls with myself or one of our other UK based specialists ensure
                                            that:
                                        </strong>
                                    </p>

                                    <EmailCallout />

                                    <p>
                                        In most cases, one phone call is all it takes and you could protect your loved
                                        ones in as little as 24 hours. If you have an impairment which means you cannot
                                        easily speak on the phone, please request support here:{' '}
                                        <a
                                            className="c-link-internal"
                                            href="/accessibility"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            Inclusion & Accessibility
                                        </a>
                                        .
                                    </p>

                                    <p>
                                        If you would prefer to book a call-back at a time which suits you, please book
                                        an appointment using the button below.
                                    </p>

                                    <CtaContainer align={'centered'} variant={'compact'}>
                                        <ButtonLink
                                            data={{ url: '#form', title: 'Book an Appointment' }}
                                            variant={'alt5'}
                                            size={'lg'}
                                            style={{
                                                marginBottom: '15px'
                                            }}
                                        />
                                    </CtaContainer>

                                    <p>
                                        <strong>{content.disclaimer}</strong>
                                    </p>
                                </div>
                            </div>
                        </Section>
                    </div>
                </div>

                <FooterEmail />
            </main>
        </LayoutInner>
    )
}

export default NotQuotedEmailTemplate
